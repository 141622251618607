// .container,
// .container-fluid {
//     width: 100%;
//     padding: 0 16px;
//     margin-right: auto;
//     margin-left: auto;
// }

// .container {
//     margin: 0 auto;

//     @include xs {
//         width: 100%;
//     }

//     @include sm {
//         max-width: 540px;
//     }

//     @include md {
//         max-width: 720px;
//     }

//     @include lg {
//         max-width: 960px;
//     }

//     @include xl {
//         max-width: 1140px;
//     }
// }

.grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
}