//Media queries mixins
@mixin xss {
    @media screen and (max-width: 350px) {
        @content;
    }
}

@mixin xs {
    @media screen and (min-width: 0) and (max-width: calc(#{$sm} - 1px)) {
        @content;
    }
}

@mixin sm {
    @media screen and (min-width: $sm) and (max-width: calc(#{$md} - 1px)) {
        @content;
    }
}

@mixin md {
    @media screen and (min-width: $md) and (max-width: calc(#{$lg} - 1px)) {
        @content;
    }
}

@mixin lg {
    @media screen and (min-width: $lg) and (max-width: calc(#{$xl} - 1px)) {
        @content;
    }
}

@mixin xl {
    @media screen and (min-width: $xl) {
        @content;
    }
}

//Function sizing cols
@mixin equalBreakpoint($repeats: 1, $value: 100%) {
    grid-template-columns: repeat($repeats, calc(#{$value} - 24px));

    @include xs {
        grid-template-columns: repeat($repeats, calc(#{$value} - 12px));
    }
}