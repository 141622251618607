@import url("https://use.typekit.net/zfb3ije.css");

//Breakpoints 
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

//Fonts
$main-font: muli, sans-serif;

//Color
$main-color: #003D89;