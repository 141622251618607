//Global settings
@import 'variables';
@import 'mixins';
@import 'global';

body, html {
    font-family: $main-font;

    @include xs {
        overflow: hidden;
    }

    @include sm {
        overflow: hidden;
    }
}

.main {
    width: 100%;
    height: 100vh;
    position: relative;
    padding: 64px;
    color: rgba($color: #000000, $alpha: 0.54);

    @include xs {
        padding: 24px 8px;
    }

    @include sm {
        padding: 24px 8px;
    }

    .row {
        @include xs {
            align-items: start !important;
        }

        @include sm {
            align-items: start !important;
        }
    }

    &-title {
        font-weight: 700;
        margin-bottom: 16px;

        @include xss {
            font-size: 16px !important;
        }

        @include xs {
            font-size: 18px;
            margin-bottom: 8px;
        }

        @include sm {
            font-size: 18px;
            margin-bottom: 8px;
        }
    }
    
    &-liv {
        color: $main-color;
        font-size: 64px;
        font-weight: 800;

        @include xss {
            font-size: 40px !important;
        }

        @include xs {
            font-size: 48px;
        }

        @include sm {
            font-size: 48px;
        }
    }

    &-subtitle {
        font-weight: 700;
        margin-bottom: 32px;

        @include xss {
            font-size: 16px !important;
        }

        @include xs {
            font-size: 18px;
            margin-bottom: 16px;
        }

        @include sm {
            font-size: 18px;
            margin-bottom: 16px;
        }
    }

    &-photo {
        // clip-path: polygon(25% 0%, 100% 0, 100% 100%, 0% 100%);
        height: 80%;
        position: absolute;
        right: 10%;
        bottom: 32px;

        @include xss {
            height: 50% !important;
            // clip-path: circle(40% at 50% 40%);
            top: unset !important;
            bottom: -60px !important;
            right: 16px !important;
        }

        @include xs {
            height: 60%;
            // clip-path: circle(40% at 50% 40%);
            top: unset;
            bottom: -90px;
            right: 16px;
        }

        @include sm {
            height: 60%;
            // clip-path: circle(40% at 50% 40%);
            top: unset;
            bottom: -90px;
            right: 16px;
        }
    }

    &-logo {
        height: 32px;
        margin-top: 32px;

        @include xss {
            height: 20px !important;
            margin-top: 16px;
        }

        @include xs {
            height: 24px;
        }

        @include sm {
            height: 24px;
        }
    }

    &-desc {
        font-size: 20px;

        @include xss {
            font-size: 13px !important;
        }

        @include xs {
            font-size: 15px;
        }

        @include sm {
            font-size: 15px;
        }
    }

    &-store {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        align-items: center;
        justify-content: flex-start;

        &-link {
            transform: scale(1);
            transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);

            &:not(:last-child) {
                margin-right: 24px;
            }

            &:hover {
                transform: scale(1.05);
            }
        }

        &-img {
            height: 48px;

            @include xss {
                height: 32px !important;
            }

            @include xs {
                height: 36px;
            }

            @include sm {
                height: 36px;
            }
        }
    }

    .container, .row {
        height: 100%;
    }
}

// @media screen and (min-height: 850px) {
//     .main {
//         &-desc {
//             width: 80%;
//         }

//         &-subtitle {
//             width: 80%;
//         }
//     }
// }

@media screen and (max-width: 350px) {
    .main-photo {
        
    }

    .main-desc {
        font-size: 14px;
    }
}